<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {computed} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    const route    = useRoute();
    const store    = useStore();
    const country  = store.state.countries.find(d => d.url == route.params.country);
    const contract = computed( () => {
      if(!store.state.contracts[country.id]) return null;
      return store.state.contracts[country.id].find(d => d.id == route.params.id)
    });

    const isURL = str => {
        return str.startsWith("http");
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      country,
      contract,
      format : store.getters.formatNumber,
      isURL
    }
  }
}
</script>


<template>
    <section>
    <div class="container" v-if="contract">
        <div class="row">
            <div class="col-12">
                <p>
                  <!-- <router-link :to="`/${this.$route.params.country}/contratos`" class="od_link">Volver a lista de contratos</router-link> -->
                  <router-link :to="{ name: 'Contracts', params: { country: country.url }}">Volver a lista de contratos</router-link>
                </p>

                <div class="ip_separator bottom"></div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-6">
                <h6>Contrato: <strong>{{contract.id}}</strong></h6>
            </div>
            <div class="col-sm-6">
                <h6 class="text-right">Fecha: <strong>{{contract.contract || contract.registration_date || contract.year}}</strong></h6>
            </div>
        </div>
        <div class="row mb-4 mt-3">
             <div class="col-12">
                <h4>{{contract.title}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="im_box_row back">
                    <h6 class="mb-0"><strong>Monto</strong></h6>
                    <h3 class="mb-3">${{format(contract.amount)}} <span>{{contract.currency}}</span></h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
            
                    <div class="im_box_row">
                        <div class="col-sm-6">
                            <h6><strong>Insitución</strong></h6>
                            <h4>{{contract.buyer}}</h4>
                        </div>
                        <div class="col-sm-6">
                            <h6><strong>Proveedor</strong></h6>
                            <h4>{{contract.provider ? contract.provider : 'Sin información.'}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="im_box_row back">
                    <h6 class="mb-3"><strong>Productos</strong></h6>
                    <p v-if="contract.items">{{contract.items}}</p>
                    <p v-else>Sin Productos</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="im_box_row">
                    <h6>Archivo</h6>  
                    <p v-if="contract.file && isURL(contract.file)">
                      <a :href="`${contract.file}`" class="od_link" download>Consultar link</a>
                    </p>     
                    <p v-else-if="contract.file && contract.ocid">
                      <a :href="`/js/data/contracts/${country.id}/${contract.file}`" class="od_link" download>Consultar ocds</a>
                    </p> 
                    <p v-else-if="contract.file">
                      <a :href="`/documents/${contract.file}`" class="od_link" download>Consultar archivo</a>
                    </p>   
                    <p v-else>Sin archivo</p>
                </div>
            </div>
            <div class="col-12">
                <div class="ip_separator bottom"></div>
            </div>
        </div>
    </div>
    </section>
</template>